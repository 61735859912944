"use client";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn, getUserProfilePictureLink } from "@/lib/utils";
import { CreditCard, HandCoins, LogOutIcon, UserIcon } from "lucide-react";
import { signOut } from "next-auth/react";
import { useRouter } from "next/navigation";
import { toast } from "sonner";
import { User } from "next-auth";
import Link from "next/link";
import { useRoleAuth } from "@/lib/hooks";
import { Permission } from "@prisma/client";

interface UserAvatarProps {
  withDropdown?: boolean;
  user: User;
  className?: string;
  tempUrl?: string;
}

export default function UserAvatar({
  withDropdown,
  className,
  tempUrl,
  user,
}: UserAvatarProps) {
  const { replace, refresh } = useRouter();
  const canViewBilling = useRoleAuth("billing", Permission.READ);

  async function logout() {
    try {
      await signOut({ redirect: false });
      replace("/");
      refresh();
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong, please try again.");
    }
  }

  const Main = (
    <Avatar
      className={cn(
        "select-none size-9",
        withDropdown && "cursor-pointer",
        className,
      )}
    >
      <AvatarImage
        className="object-cover"
        alt={user?.name!}
        src={getUserProfilePictureLink(tempUrl ?? user?.image!)}
      />
      <AvatarFallback>{user?.name?.[0]}</AvatarFallback>
    </Avatar>
  );

  if (!withDropdown) return Main;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{Main}</DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="min-w-[14rem] max-w-[15rem]">
        <DropdownMenuLabel className="text-sm grid">
          <div className="font-semibold" id="user-name">
            {user?.name}
          </div>
          <div className="text-zinc-500 font-normal">{user?.email}</div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link className="cursor-pointer" href="/account">
            <UserIcon className="h-5 w-5 mr-2" />
            My Account
          </Link>
        </DropdownMenuItem>
        {canViewBilling && (
          <DropdownMenuItem asChild>
            <Link className="cursor-pointer" href="/account/plans">
              <CreditCard className="h-5 w-5 mr-2" />
              Plans
            </Link>
          </DropdownMenuItem>
        )}
        {canViewBilling && (
          <DropdownMenuItem asChild>
            <Link className="cursor-pointer" href="/account/billing">
              <HandCoins className="h-5 w-5 mr-2" />
              Billing
            </Link>
          </DropdownMenuItem>
        )}

        <DropdownMenuSeparator />
        <DropdownMenuItem className="cursor-pointer" onClick={() => logout()}>
          <LogOutIcon className="h-5 w-5 mr-2" />
          Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
